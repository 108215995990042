.aboutUs {
    padding: rem(93) 0 rem(204) 0;
    border-bottom:1px solid $lightestGrey;
    &__wrapper {
        display: flex;
    }

    &__content {
        flex-grow: 1;
        display: grid;

        grid: {
            template-rows: auto;
            template-columns: auto rem(485);
        }
    }

    &__image {
        flex-shrink: 0;
        flex-basis: rem(485);
        margin: rem(104) rem(83) 0 rem(67);

        grid: {
            column: 2 / span 2;
            row: 1 / span 10;
        }
    }

    &__image .imageBorderBlock {
        position: relative;

        &:before {
            content: "";
            background: $darkGrey;
            width: 100%;
            height: 100%;
            position: absolute;
            top: rem(-104);
            right: rem(-85);
            z-index: -1;
        }

        &:after {
            content: "";
            background: none;
            border: rem(10) solid $primary;
            width: calc(100% - 20px);
            height: calc(100% + 61px);
            position: absolute;
            top: rem(-40);
            right: rem(-40);
            z-index: 1;
        }
    }

    &__image .imageBorderBlock img {
        width: 100%;
        height: auto;
    }

    &__content h3 {
        font-size: rem(48);
        color: $offBlack;
        margin: 0 0 rem(34) 0;
        line-height: 1.2;
    }

    &__content p {
        color: $offGrey;
    }

    &__content .btn {
        margin: rem(35) 0 0 0;
        width: rem(170);
    }
}

@media(max-width:em(1199)) {
    .aboutUs {
        padding:rem(95) 0 rem(120) 0;
    }
    .aboutUs__content h3 {
        font-size: rem(42);
    }

    .aboutUs__image {
        flex-basis: rem(293);
    }

    .aboutUs__content {
        grid-template-columns: auto rem(293);
    }
}

@media(max-width:em(991)) {
    .aboutUs__content h3 {
        font-size: rem(34);
    }
}

@media(max-width:em(770)) {
    .aboutUs {
        padding: rem(45) 0;
    }

    .aboutUs__content {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
    }

    .aboutUs__content h3 {
        font-size: rem(30);
    }

    .aboutUs__content p,
    .aboutUs__content .btn {
        order: 3;
    }

    .aboutUs__image {
        order: 2;
        margin: rem(104) rem(83) rem(70) rem(15);
        flex-basis: calc(100vw - 160px);
    }
}

@media(max-width:em(480)) {
    .aboutUs__image .imageBorderBlock:before {
        top: rem(-50);
        right: rem(-50);
    }

    .aboutUs__image .imageBorderBlock:after {
        height: calc(100% + 30px);
        top: rem(-25);
        right: rem(-25);
    }

    .aboutUs__image {
        margin: rem(40) 0 rem(60) rem(35);
    }
}