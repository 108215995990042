* {
    box-sizing: border-box;
}
html {
    font-size:16px;
    scroll-behavior: smooth;
}
body {
    color:$bodyText;
    &.noOverflow {
        overflow:hidden;
    }
}
a {
    color:$bodyText;
}
.container {
    width:rem(1212);
    max-width:100%;
    margin:0 auto;
    padding:0 rem(20);
}
img {
    max-width:100%;
    height:auto;
}

hr {
    margin:rem(50) 0;
    border:none;
    border-top:1px solid #cccccc;
}

.twoCol {
    width:100%;
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-gap:rem(33);
}
.oneCol {
    width:100%;
}

@media(max-width:em(991)) { 
    .twoCol {
        grid-template-columns: 1fr;
    }
}