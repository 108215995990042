.serviceItem {
    border:rem(3) solid $borderGrey;
    padding:rem(61) rem(20);
    text-align: center;
    background:$baseWhite;
    text-decoration: none;
}
.serviceItem img {
    width:rem(67);
    height:auto;
    margin:0 0 rem(20) 0;
}
.serviceItem h4 {
    font-size:rem(30);
    font-weight:$semi;
    margin:0 0 rem(30) 0;
    padding:0 0 rem(30) 0;
    position:relative;
    &:after {
        content:"";
        position:absolute;
        bottom:0;
        left:0;
        right:0;
        width:rem(75);
        height:1px;
        background:$baseBlack;
        margin:0 auto;
    }
}
.serviceItem a {
    text-decoration: none;
    font-size:rem(18);
    font-weight:$semi;
}

@media(max-width:em(991)) {
    .serviceItem h4 {
        font-size:rem(24);
    }
}