.testimonials {
    padding:rem(93) 0;
    position: relative;
    &:before {
        content:"";
        position:absolute;
        bottom: rem(-172);
        left: rem(-250);
        width:rem(549);
        height:rem(543);
        background:url('../images/logo-bg.png') no-repeat;
        background-size:contain;
        z-index:0;
    }
    .testimonialItems {
        display:grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap:rem(67);
    }
}

@media(max-width:em(770)) {
    .testimonials {
        padding:rem(45) 0 rem(75) 0;
    }
    .testimonials:before {
        display:none;
    }
    .testimonials .testimonialItems {
        grid-template-columns: 1fr;
    }
}


@media(max-width:em(480)) {
    .testimonials {
        padding:rem(45) 0;
    }
}