.contact {
    padding:rem(110) 0;
    &__wrapper {
        display:grid;
        grid-template-columns: 0.7fr 0.5fr;
        grid-gap:rem(70);
    }
    .contactMap img {
        width:100%;
        height:auto;
    }
    &__abuse h3 {
        margin:0 0 rem(20) 0;
    }
}
@media(max-width:em(770)) { 
    .contact {
        padding:rem(45) 0;
    }
    .contact__wrapper {
        grid-template-columns: 1fr;
        grid-gap:rem(30);
    }
}