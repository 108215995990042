.contactPanel {
    padding:rem(110) 0 rem(80) 0;
    background:$secondary url('../images/contact-bg.png') no-repeat;
    background-size: 50vw;
    background-position: 39vw rem(100);
    overflow: hidden;
    color:$baseWhite;
    .contactItems {
        display: flex;
        justify-content: space-between;
    }
    .contactItem {
        text-align: center;
        display:flex;
        &__img {
            flex-shrink:0;
            flex-basis:rem(91);
            margin-right:rem(48);
        }
        &__info {
            text-align: left;
            margin-top:rem(13);
        }
        svg {
            flex-shrink:0;
            height:rem(87);
            fill:$baseWhite;
            max-width:rem(91);
        }
        a {
            color:$baseWhite;
            text-decoration: none;
            display:block;
        }
        a,address {
            font-size:rem(18);
            line-height:rem(30);
        }
        h4 {
            font-size:rem(16);
            font-weight:$bold;
            margin-bottom:rem(15);
        }
    }
}

@media(max-width:em(1199)) {
    .contactPanel .contactItem svg {
        height:rem(60);
        max-width:rem(60);
    }
    .contactPanel .contactItem__img {
        margin-right:rem(20);
    }
    .contactPanel .contactItem a, .contactPanel .contactItem address {
        font-size:rem(16);
    }
}

@media(max-width:em(770)) {
    .contactPanel {
        background-size: 100%;
        background-position: 50% -100%;
        padding:rem(45) 0;
    }
    .contactPanel .contactItems {
        flex-direction: column;
        grid-gap:0;
    }
    .contactPanel .contactItem svg {
        height:rem(87);
        max-width:rem(91);
    }
    .contactPanel .contactItem__img {
        margin-right:rem(48);
    }
    .contactPanel .contactItem a, .contactPanel .contactItem address {
        font-size:rem(18);
    }
    .contactPanel .contactItem {
        width:rem(320);
        margin:0 auto rem(45) auto;
    }
    .contactPanel .contactItem:last-of-type {
        margin-bottom:0;
    }
}

@media(max-width:em(610)) {
    .contactPanel .contactItem svg {
        height:rem(60);
        max-width:rem(60);
    }
    .contactPanel .contactItem {
        width:rem(290);
        margin:0 auto rem(45) auto;
    }
    .contactPanel .contactItem__img {
        margin-right:rem(20);
    }
}