.contactForm {
    &__intro {
        .introTitle {
            margin:0 0 rem(15) 0;
        }
        h3 {
            line-height:1.2;
            margin:0 0 rem(40) 0;
        }
    }
    &__extra h3 {
        margin:0 0 rem(20) 0;
    }
    &__form .oneCol,
    &__form .twoCol {
        margin-bottom:rem(27);
    }
    .radioGroup {
        display:flex;
        grid-gap:rem(46);
    }
    input[type="submit"] {
        width:rem(200);
        margin-top:rem(27);
    }
    label {
        margin-bottom:rem(10);
        display: block;
        font-weight:bold;
    }
    p {
        color:$bodyText;
        margin-bottom:rem(15);
    }
}

@media(max-width:em(770)) { 
    .contactForm__intro h3 br {
        display:none;
    }
}