.introBanner {
    background:url('../images/intro-bg.jpg') no-repeat;
    background-size:cover;
    background-position: center center;
    height:rem(388);
    &__wrapper {
        display:flex;
        align-items:center;
        justify-content: center;
        height:100%;
        text-align: center;
    }
    h1 {
        color:$baseWhite;
        font-size:rem(53.65);
        margin: 0;;
    }
}

@media(max-width:em(991)) {
    .introBanner {
        height:rem(275);
    }
    .introBanner h1 {
        font-size:rem(34.02);
    }
}

@media(max-width:em(610)) {
    .introBanner {
        height:rem(170);
    }
}