.loginForm {
  text-align: center;
  width: 50vw;
  height: 33vh;
  margin-top: 33vh;
  margin-left: 25vw;

  label {
    margin-bottom:rem(10);
    margin-top:rem(10);
    display: block;
    font-weight:bold;
    text-align: initial;
  }

  input[type="submit"] {
    width:rem(200);
    margin-top:rem(27);
  }

}