.introduction {
    margin: 0 0 rem(52) 0;
    text-align: center;
    p,h3 {
        font-weight:$semi;
    }
    h3 {
        font-size:rem(40);
        margin-bottom:rem(20);
    }
    p {
        font-weight:$semi;
        line-height:1.2;
        width:rem(390);
        max-width:100%;
        margin:0 auto;
    }
}