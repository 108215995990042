.sidebar {
    .sidebarItem {
        background:$sidebar;
        padding:rem(30) rem(22) rem(40) rem(22);
        margin:0 0 rem(40) 0;
    }
    h3 {
        font-size:rem(22);
        font-weight:$semi;
        padding:0 0 rem(30) 0;
        margin:0 0 rem(13) 0;
        position:relative;
        &:before {
            content:"";
            position:absolute;
            bottom:0;
            left:0;
            right:0;
            height:rem(2);
            background:#b4b4b4;
        }
        &:after {
            content:"";
            position:absolute;
            bottom:0;
            left:0;
            width:rem(37);
            height:rem(2);
            background:$primary;
        }
    }
    ul {
        display:flex;
        flex-direction: column;
    }
    ul li {
        padding:rem(17) 0;
        font-weight:$semi;
        border-top:1px solid #dfdfdf;
        border-bottom:1px solid #dfdfdf;
        margin:0;
    }
    ul li:first-of-type {
        border-top:none;
        border-bottom:none;
    }
    ul li:before {
        display:none;
    }
    ul li.active a {
        color:$primary;
    }
    ul li a {
        color:#2a2a2a;
        text-decoration: none;
    }
    form {
        margin-top:rem(30);
    }
    input:not([type="submit"]):not([type="radio"]):not([type="checkbox"]), textarea {
        height:rem(35);
        border:none;
        background:$baseWhite;
        margin-bottom:rem(15);
        padding:0 rem(15);
    }
    textarea {
        height:rem(155);
        padding:rem(15);
    }
    .btn {
        border-radius: 0;
        width:rem(122);
    }
}