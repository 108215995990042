.solutions {
    background:#262626 url('../images/solutions-bg.png') no-repeat;
    background-size:cover;
    background-position: center center;
    color:$baseWhite;
    padding:rem(167) 0;
    &__content {
        text-align: center;
    }
    h3 {
        font-size:rem(40);
        margin-bottom:rem(25);
        font-weight:$semi;
        line-height:1.2;
    }
    p {
        font-size:rem(20);
        line-height:1.2;
    }
    .btn {
        width:rem(235);
        max-width:100%;
        margin-top:rem(52);
    }
}
@media(max-width:em(1199)) {
    .solutions h3 {
        font-size:rem(36);
    }
}

@media(max-width:em(991)) {
    .solutions h3 {
        font-size:rem(34);
    }
    .solutions p {
        font-size:rem(18);
    }
}

@media(max-width:em(770)) {
    .solutions__content {
        text-align: left;
    }
    .solutions h3 {
        font-size:rem(28);
    }
    .solutions p {
        font-size:rem(16);
    }
}

@media(max-width:em(770)) {
    .solutions {
        padding:rem(45) 0;
    }
}