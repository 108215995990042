.btn {
    display:inline-block;
    font-size:rem(16);
    line-height:1;
    font-weight:$medium;
    cursor:pointer;
    text-decoration: none;
    padding:rem(15.5) rem(10);
    border-radius:rem(5);
    border:2px solid transparent;
    text-align: center;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    opacity:1;
    &--primary {
        background:$primary;
        color:$baseWhite;
    }
    &--secondary {
        background:#2d2d9c;
    }
    &--dark {
        background:$bodyText;
        color:$baseWhite;
    }
    &--outlined {
        border-color:$lightGrey;
        color:$medGrey;
    }
    &--whiteOutlined {
        border-color:$baseWhite;
        color:$baseWhite;
    }
}