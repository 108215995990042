$light: 300;
$roman: 400;
$medium: 500;
$semi: 600;
$bold: 700;


$baseFontFamily: 'Montserrat', sans-serif;

body {
  font-family:$baseFontFamily;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $baseFontFamily;
  font-weight:$bold;
}

h3 {
  font-size:rem(36);
}

p {
  font-size:rem(16);
  line-height:rem(28);
}

.introTitle {
  font-size:rem(16);
  display:block;
  position:relative;
  line-height:1.2;
  font-weight:$semi;
  margin:0 0 rem(20) 0;
  &--bordered {
    padding:0 0 0 rem(63);
    &:before {
      content:"";
      position:absolute;
      top:rem(8);
      bottom:0;
      left:0;
      width:rem(45);
      height:rem(2);
      display:flex;
      align-items: center;
      background:$primary;
    }
  }
}

@media(max-width:em(991)) {
  h3 {
    font-size:rem(28);
  }
}

@media(max-width:em(770)) {
  .introTitle {
    display:none;
    text-align: center;
    padding: 0;
    width: 100%;
  }
  .introTitle--bordered {
    display: block;
  }
  .introTitle--bordered:before {
    display:none;
  }
  .introTitle--largeMobile {
    font-size:rem(30);
  } 
}