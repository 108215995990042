.header {
    &__wrapper {
        display:flex;
        justify-content: space-between;
        align-items: center;
        padding:rem(25) rem(20);
    }
    &__logo {
        width:rem(223);
    }
    &__logo img {
        width:100%;
        height:auto;
    }
    &__actions {
        display:grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap:rem(11);
    }
    &__actions .btn {
        width:rem(102);
    }
    &__nav {
        align-self: center;
    }
    &__hamburger {
        background:$primary;
        padding:rem(10);
        line-height:0;
        display:none;
        position:relative;
        z-index:6;
    }
}

@media(max-width:em(1199)) {
    .header__logo {
        width:rem(180);
    }
}
@media(max-width:em(991)) {
    .header__wrapper {
        padding:rem(10) rem(20);
    }
    .header__hamburger {
        display:block;
    }
    .header__actions {
        display:none;
    }
    .header__nav {
        display:none;
        position:fixed;
        top:0;
        bottom:0;
        left:0;
        right:0;
        z-index:5;
        background:$baseWhite;
    }
    .header.active .header__nav {
        display:block;
    }
    .header.active .header__actions {
        display: block;
        position: fixed;
        z-index: 6;
        bottom: rem(20);
        left: 0;
        right: 0;
        text-align: center;
    }
}