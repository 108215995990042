.services{
    padding:rem(93) 0;
    position:relative;
    overflow: hidden;
    &:before {
        content:"";
        position:absolute;
        bottom:0;
        right:rem(-178);
        width:rem(549);
        height:rem(543);
        background:url('../images/logo-bg.png') no-repeat;
        background-size:contain;
        z-index:0;
    }
    &__wrapper {
        position:relative;
        z-index:1;
    }
    .serviceItems {
        display:grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap:rem(32);
    }
}

@media(max-width:em(770)) {
    .services {
        padding: rem(45) 0;
    }
    .services .serviceItems {
        grid-template-columns: 1fr;
        grid-gap:rem(20);
    }
}