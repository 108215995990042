.subscribe {
    padding:rem(128) 0 rem(72) 0;
    &__content {
        display:flex;
    }
    &__title {
        flex-shrink:0;
        flex-basis:rem(258);
        text-align: center;
        margin-right:rem(150);
    }
    .introTitle {
        margin:0 0 rem(5) 0;
    }
    h3 {
        font-size:rem(45.74);
        font-weight:$semi;
    }
    .subscribeForm {
        flex-grow:1;
    }
}

@media(max-width:em(1199)) {
    .subscribe h3 {
        font-size:rem(36);
    }
}

@media(max-width:em(991)) {
    .subscribe h3 {
        font-size:rem(34);
    }
    .subscribe__content {
        flex-direction: column;
    }
    .subscribe__title {
        margin:0 0 rem(25) 0;
        flex-basis: 100%;
    }
}

@media(max-width:em(770)) {
    .subscribe h3 {
        font-size:rem(28);
    }
}

@media(max-width:em(770)) {
    .subscribe {
        padding:rem(45) 0;
    }
}