.navigation {
    ul {
        display:flex;
    }
    ul > li {
        margin:0 rem(40) 0 0;
        display:flex;
        flex-direction: column;
        align-items: center;
        font-weight:$bold;
    }
    ul > li:last-of-type {
        margin:0;
    }
    ul > li:hover,
    ul > li.active {
        a {
            color:$primary;
        }
        svg path,
        svg ellipse,
        svg circle {
            stroke:$primary;
        }
        svg {
            fill:$primary;
        }
    }
    ul > li > a {
        text-decoration: none;
        display:flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    ul > li svg {
        width:rem(17);
        height:auto;
        margin:0 0 rem(10) 0;
    }
}

@media(max-width:em(1199)) {
    .navigation ul>li {
        margin:0 rem(20) 0 0;
    }
}
@media(max-width:em(991)) {
    .navigation ul {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    .navigation ul > li {
        flex-direction: row;
        align-items: center;
        margin:0 0 rem(35) 0;
        font-size:rem(20);
        width:rem(288);
        text-align: left;
        font-size:rem(32);
    }
    .navigation ul > li > a {
        flex-direction: row;
    }
    .navigation ul > li svg {
        margin:0 rem(20) 0 0;
        width:rem(30);
    }
}

@media(max-width:em(770)) {
    .navigation ul > li {
        font-size:rem(16);
        width:rem(155);
    }
    .navigation ul > li svg {
        margin:0 rem(15) 0 0;
        width:rem(22);
    }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation : landscape) { 
    .navigation ul>li {
        margin-bottom:rem(15);
    }
    .navigation ul {
        justify-content: flex-start;
        padding-top:rem(20);
    }
}