.banner {
    background:url('../images/desktop-banner.jpg') no-repeat;
    background-size:cover;
    background-position: center center;
    height:rem(852);
    &__wrapper {
        display:flex;
        align-items:center;
        justify-content: center;
        height:100%;
        text-align: center;
    }
    &__content h1 {
        color:$primary;
        font-size:rem(53.65);
        margin: 0 0 rem(25) 0;
    }
    &__content h1 > span {
        display:block;
        color:$baseWhite;
        font-size:rem(74.41);
        margin:rem(5) 0 0 0;
        letter-spacing: rem(4);
    }
    &__content p {
        font-size:rem(31.71);
        color:$baseWhite;
        margin:0 0 rem(48) 0;
        line-height: 1.2;
    }
    &__content .btn {
        font-size:rem(18);
        width:rem(230);
        padding:rem(18.5);
    }
}

@media(max-width:em(991)) {
    .banner {
        height:rem(426);
    }
    .banner__content h1 {
        font-size:rem(34.02);
    }
    .banner__content h1 > span {
        font-size:rem(47.19);
    }
    .banner__content p {
        font-size:rem(20);
    }
    .banner__content .btn {
        font-size:rem(16);
        width:rem(200);
        padding:rem(15.5);
    }
}
@media(max-width:em(610)) {
    .banner {
        height:rem(426);
    }
    .banner__wrapper {
        justify-content: flex-start;
        text-align: left;
    }
    .banner__content p {
        font-size:rem(16);
        margin:0 0 rem(30) 0;
    }
    .banner__content .btn {
        font-size:rem(16);
        width:rem(200);
        padding:rem(15.5);
    }
}