.testimonial {
    display:flex;
    color:$testimonialGrey;
    font-size:rem(15);
    .stars {
        display:flex;
        width:rem(72);
        svg {
            height:rem(14);
            fill:#faa634;
        }
    }
    &__image {
        text-align: center;
        flex-shrink:0;
        flex-basis:rem(92);
        margin-right:rem(22);
    }
    &__image p {
        margin-top:rem(20);
    }
    &__content {
        padding:rem(40) rem(32);
        background:$offWhite;
        box-shadow: 0px 1px 5px 0px rgba(227,227,227,1);
        position:relative;
        &:before {
            content:"";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 9.5px 10px 9.5px 0;
            border-color: transparent $offWhite transparent transparent;
            position:absolute;
            left:rem(-10);
            top:rem(37);

        }
    }
    &__content p {
        font-size:rem(15);
    }
    &__details {
        display:flex;
        justify-content: space-between;
        align-items: center;
        margin:rem(28) 0 0 0;
    }
    &__reviews {
        display:flex;
        align-items: center;
    }
    &__reviews .stars {
        margin-right:rem(22);
    }
}

@media(max-width:em(1199)) {
    .testimonial__image {
        flex-basis:rem(70);
    }
}

@media(max-width:em(770)) {
    .testimonial {
        position:relative;
    }
    .testimonial__image {
        flex-basis:rem(140);
    }
    .testimonial__image img {
        width:100%;
    }
    .testimonial__content {
        background:none;
        box-shadow:none;
        padding:0;
        position:static;
    }
    .testimonial__reviews {
        position:absolute;
        top:rem(200);
        left:0;
        width: rem(140);
        justify-content: center;
    }
    .testimonial__reviews .stars {
        margin-right:rem(5);
    }
    .testimonial__content {
        text-align: center;
    }
    .testimonial__author {
        width:100%;
    }
}