.footer {
    background:#272727 url('../images/globe-footer.png') no-repeat;
    background-position: top center;
    padding:rem(88) 0 0 0;
    a, p, span, div {
        color:#dddddd;
    }
    .footerPanels {
        display:grid;
        grid-template-columns:repeat(3, 1fr);
        grid-gap:rem(31);
        padding-bottom:rem(46);
    }
    .footerPanel {
        &__head {
            margin-bottom:rem(30);
            min-height:rem(53);
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            flex-direction: column;
        }
        &__head img {
            width:rem(220);
            height:auto;
        }
        &__head h4 {
            margin-bottom:rem(10);
        }
        &__content p,
        &__content a {
            font-size:rem(15);
            line-height:rem(24);
        }
        &__content ul li {
            margin-bottom:rem(4);
        }
        &__content ul li:last-of-type {
            margin-bottom:0;
        }
        &__content ul li a {
            text-decoration: none;
        }
        .newsItem {
            padding-bottom:rem(20);
            margin-bottom:rem(20);
            border-bottom:1px solid #666666;
        }
        .newsItem:last-of-type {
            border:none;
        }
        .newsItem a{
            text-decoration: none;
        }
        .newsItem__info {
            display:flex;
            align-items: center;
        }
        .newsItem__infoItem {
            display:flex;
            margin-right:rem(28);
        }
        .newsItem__infoItem:last-of-type {
            margin-right:0;
        }
        .newsItem__title {
            margin-bottom:rem(20);
        }
        .newsItem__infoItem span {
            font-size:rem(14);
        }
        .newsItem__infoItem img {
            height:rem(12);
            margin-right:rem(12);
        }
        input[type="email"] {
            margin-bottom:rem(11);
            height:auto;
        }
        textarea {
            margin-bottom:rem(22);
            height:rem(85);
        }
        input[type="submit"] {
            padding:rem(8) rem(30.5);
        }
        input[type="email"],
        textarea {
            background:#323232;
            color:#dddddd;
            font-size:rem(15);
            border:none;
            padding:rem(11) rem(18);
            display:block;
            width:100%;
        }
    }
    &__social {
        padding:rem(12) 0;
        border-top:1px solid #666666;
        border-bottom:1px solid #666666;
    }
    &__social ul {
        display:flex;
        justify-content: center;
    }
    &__social ul li {
        margin:0 rem(6.5);
    }
    &__social li a {
        width:rem(33);
        height:rem(33);
        border:1px solid #dddddd;
        border-radius: 100%;
        display:flex;
        align-items: center;
        justify-content: center;
    }
    &__social li a img {
        height:rem(11);
    }
    &__copy {
        display:flex;
        justify-content: space-between;
        align-items: center;
        padding:rem(18) 0;
    }
    .backToTop {
        display:flex;
        align-items: center;
        text-decoration: none;
        cursor:pointer;
    }
    .backToTop button {
        cursor:pointer;
        background:$primary;
        width:rem(22);
        height:rem(22);
        display:flex;
        align-items: center;
        justify-content: center;
        outline:none;
        border:none;
        margin-left:rem(20);
    }
    .backToTop img {
        height:rem(8);
    }
}

@media(max-width:em(991)) {
    .footer .footerPanels {
        grid-template-columns: repeat(3, 1fr);
    }
    .footer__panel:first-of-type {
        grid-column-end: span 4;
    }
}
@media(max-width:em(770)) {
    .footer {
        padding:rem(45) 0 0 0;
    }
    .footerPanels {
        text-align: center;
    }
    .footer .footerPanel__head img {
        margin:0 auto;
    }
    .footer .footerPanel__head {
        align-items: center;
    }
    .footer .footerPanels {
        grid-template-columns: 1fr;
    }
    .footer__panel:first-of-type {
        grid-column-end: span 1;
    }
    .footer .footerPanel__head {
        min-height:auto;
    }
    .footer__panel--news {
        display:none;
    }
    .footer .footerPanels {
        grid-gap:rem(40);
    }
}
@media(max-width:em(480)) {
    .footer .backToTop {
        display:none;
    }
    .footer__copy {
        justify-content: center;
    }
}