.subscribeForm {
    display:flex;
    align-items: flex-start;
    input:not([type="submit"]):not([type="radio"]):not([type="checkbox"]) {
        font-size:rem(15);
        height:rem(67);
        padding:0 rem(40);
        border-radius:rem(5);
        background:#efefef;
        border:none;
        outline:none;
        flex-grow:1;
        color:$bodyText;
    }
    input[type="submit"] {
        flex-shrink:0;
        flex-basis:rem(215);
    }
    .btn {
        font-size:rem(18);
        font-weight:$semi;
        padding:rem(22.5) rem(10);
    }
}

@media(max-width:em(610)) {
    .subscribeForm {
        flex-direction: column;
    }
    .subscribeForm .btn {
        max-height:rem(67);
        width:100%;
    }
    .subscribeForm input[type="email"] {
        width:100%;
        text-align: center;
    }
}