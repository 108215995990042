#hamburger {
    width: rem(24);
    height: rem(14.1);
    position: relative;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
    background: none;
    border: none;
    display: inline-block;
    -webkit-appearance: none;
}

#hamburger span {
    display: block;
    position: absolute;
    height: rem(1.5);
    width: 100%;
    background: $baseWhite;
    border-radius: rem(9);
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
}

#hamburger span:nth-child(1) {
    top: 0px;
}

#hamburger span:nth-child(2),
#hamburger span:nth-child(3) {
    top: rem(6);
}

#hamburger span:nth-child(4) {
    top: rem(12);
}


#hamburger.open span:nth-child(1) {
    top: rem(6);
    width: 0%;
    left: 50%;
}

#hamburger.open span:nth-child(2) {
    transform: rotate(45deg);
}

#hamburger.open span:nth-child(3) {
    transform: rotate(-45deg);
}

#hamburger.open span:nth-child(4) {
    top: rem(12);
    width: 0%;
    left: 50%;
}