.cta {
    background:$secondary;
    &__wrapper {
        padding:rem(87) rem(20);
        display:flex;
        align-items: center;
        justify-content: space-between;
    }
    * {
        color:$baseWhite;
    }
    h3 {
        font-size:rem(30);
        margin-bottom:rem(15);
        line-height:1.2;
    }
    p {
        font-weight:$medium;
    }
    .btn {
        width:rem(200);
    }
}

@media(max-width:em(991)) {
    .cta__wrapper {
        flex-direction: column;
        text-align: center;
    }
    .cta__desc {
        margin-bottom:rem(30);
    }
    .cta h3 {
        font-size:rem(26);
    }
}

@media(max-width:em(770)) {
    .cta__wrapper {
        padding:rem(45) rem(20);
    }
    .cta h3 {
        font-size:rem(24);
    }
}