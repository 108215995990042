::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #666;
    opacity: 1;
    /* Firefox */
}

input,
textarea {
    font-family: $baseFontFamily;
}

input:not([type="submit"]):not([type="radio"]):not([type="checkbox"]),
textarea,
select {
    font-size:rem(16);
    border: rem(2) solid #a1a1a1;
    height: rem(62);
    padding: 0 rem(28);
    width: 100%;
    box-sizing: border-box;
    -webkit-appearance: none;
    background: none;

    &:focus {
        outline: none;
        border-color: #a1a1a1;
    }
    &.error {
        border-color:#e22424;
        &::placeholder {
            color:#e22424;
        }
    }
}

textarea {
    padding: rem(22) rem(30);
    height: rem(184);
}
.inputElem {
    position:relative;
    &.error {
        input:not([type="submit"]):not([type="radio"]):not([type="checkbox"]),
        textarea,
        select {
            border-color:#e22424;
            &::placeholder {
                color:#e22424;
            }
        }
        label {
            color:#e22424;
        }
        .errorMsg {
            color:#e22424;
            margin-top:rem(10);
            display:block;
            font-weight:bold;
        }
    }
}
.radioItem {

    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }

    [type="radio"]:checked+label,
    [type="radio"]:not(:checked)+label {
        position: relative;
        padding-left: rem(32);
        cursor: pointer;
        line-height: rem(20);
        font-size:rem(18);
        display: inline-block;
        color: #666;
    }

    [type="radio"]:checked+label:before,
    [type="radio"]:not(:checked)+label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: rem(18);
        height: rem(18);
        border: 1px solid $primary;
        background: #fff;
    }

    [type="radio"]:checked+label:after,
    [type="radio"]:not(:checked)+label:after {
        content: '';
        width: rem(12);
        height: rem(12);
        background: $primary;
        position: absolute;
        top: rem(4);
        left: rem(4);
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }

    [type="radio"]:not(:checked)+label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    [type="radio"]:checked+label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}