.sr-only {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

a.skip-main {
    left: -999px;
    position: absolute;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    z-index: -999;
}

a.skip-main:focus,
a.skip-main:active {
    color: $bodyText;
    background-color: $baseWhite;
    left: rem(10);
    top: rem(10);
    width: rem(200);
    height: auto;
    overflow: auto;
    padding: rem(10);
    border: rem(4) solid $primary;
    text-align: center;
    font-size: rem(16);
    z-index: 999;
    outline: none;
}