$primary: #009f00;
$secondary: #000080;

$bodyText: #333333;

$baseBlack: #000000;
$baseWhite: #ffffff;

$lightestGrey: #e5e5e5;
$lightGrey: #c2c2c2;
$medGrey: #797979;
$darkGrey: #3c3c3c;
$borderGrey: #cccccc;
$testimonialGrey: #666666;

$offWhite: #fafafa;

$offGrey: #6d6d6d;

$offBlack: #000103;

$sidebar: #f1f1f1;