.content {
    padding:rem(110) 0;
    &__wrapper {
        display:grid;
        grid-template-columns: rem(271) 1fr;
        grid-gap:rem(30);
    }
    *:not(.btn):not(a) {
        color:$offGrey;
        line-height:1.4;
    }
    h1,h2,h3,h4,h5,h6 {
        font-weight:$medium;
        margin-bottom:rem(20);
    }
    h2, .h2 {
        font-size:rem(36);
    }
    h3, .h3 {
        font-size:rem(32);
    }
    h4, .h4 {
        font-size:rem(26);
    }
    h5, .h5 {
        font-size:rem(20);
    }
    h6, .h6 {
        font-size:rem(16);
    }
    a {
        color:$primary;
    }
    p {
        font-size:rem(16);
        line-height:rem(24);
        margin-bottom:rem(20);
    }
    hr {
        margin:rem(50) 0;
        border:none;
        border-top:1px solid #cccccc;
    }
    strong {
        font-weight:$medium;
    }
    ul {
        margin-bottom:rem(20);
    }
    ul li{
        position:relative;
        font-size:rem(16);
        margin-bottom:rem(10);
        padding-left:rem(20);
        &:before {
            content:"";
            position:absolute;
            left:0;
            top:rem(6);
            width:rem(8);
            height:rem(8);
            background:$primary;
            border-radius: 100%;
        }
    }
}

@media(max-width:em(770)) {
    .content {
        padding:rem(45) 0;
    }
    .content .sidebar {
        order:2;
    }
    .content__wrapper {
        grid-template-columns: 1fr;
    }
    .content hr:last-of-type {
        display:none;
    }
}